import React from "react"

function NewsletterReferralTerms() {
  return (
    <>
      <div className="columns" style={{ marginBottom: "40px" }}>
        <div className="column is-2-desktop is-2-tablet is-hidden-mobile"></div>
        <div className="column is-8-desktop is-8-tablet is-12-mobile">
          <p
            style={{
              fontSize: "3rem",
              fontWeight: "800",
              borderBottom: "2px solid #fec600",
              marginTop: "15px",
            }}
          >
            Newsletter Referral Program
          </p>
          <p
            style={{
              fontSize: "2.5rem",
              fontWeight: "600",
              // borderBottom: "2px solid #fec600",
              marginTop: "15px",
            }}
          >
            Terms and Conditions
          </p>
          {/* <br></br> */}
          <p
            style={{
              margin: "12px 0",
            }}
          >
            <strong>Last updated: Oct 11, 2021</strong>
          </p>
          <p>
            By participating in the Newsletter Referral Program, you (the
            “Referrer”) agree to abide by these Referral Program Terms &
            Conditions.
          </p>
          <h2 className="title is-3 margin-top-15px">Defined Terms</h2>
          <ul>
            <li>
              <strong>Referrer:</strong> you, a registered Voyay!ge’s newsletter
              subscriber, customer and a legal resident of the continental
              United States.{" "}
            </li>
            <li>
              <strong>Referred Customer:</strong> a friend or family member who
              has not previously subscribed to Voyay!ge’s newsletter before and
              to whom the Referrer shares their link. A “friend” is a person
              with whom you communicate frequently and have met in person
              before. A “family member” is a person who is your parent, sibling
              or child or a person with whom you are related through marriage or
              common-law partnership.
            </li>
            <li>
              <strong>Referral Link:</strong> a personalized referral link,
              provided to you by Voyay!ge Inc.
            </li>
            <li>
              <strong>Referral Discount:</strong> a promotional reward discount,
              to be used on voyayge.com website only.
            </li>
            <li>
              <strong>Referral Credit: </strong>collectively, the Referral Gift
              Card and Referral Product. Referral credits can only be applied to
              continental U.S. based customers.
            </li>
          </ul>
          <h2 className="title is-3 margin-top-15px">
            About Our Newsletter Referral Program
          </h2>
          <h2 className="title is-4 margin-top-15px">For the Referrer</h2>
          <h2 className="title is-5 margin-top-15px">
            How to Earn a Referrer Entry:{" "}
          </h2>{" "}
          <p>
            Visit{" "}
            <a href="/app/invite/newsletter">
              https://voyayge.com/app/invite/newsletter
            </a>{" "}
            and complete the form to receive a personalized referral marketing
            link. <br></br><br></br> Use your referral link or in-form share buttons to
            invite others to sign up for Voyay!ge newsletter. The referral link
            will track all new members who sign up. Upon the sign up
            attributable to your personalized referral link, you will earn one
            referral credit.
          </p>
          <h2 className="title is-5 margin-top-15px">
            There are 3 milestones:
          </h2>{" "}
          <ol type="1">
            <li style={{ marginLeft: "15px" }}>
              For <strong>1 referral credit </strong>you qualify for a one time
              referral discount of <strong>10%</strong> on a voyay!ge curated
              travel plan to be used on voyayge.com website only.
            </li>
            <li style={{ marginLeft: "15px" }}>
              For <strong>10 referral credits</strong> you qualify for a one
              time referral discount of <strong>15%</strong> on a voyay!ge
              curated travel plan to be used on voyayge.com website only.
            </li>
            <li style={{ marginLeft: "15px" }}>
              For <strong>20 referral credits</strong> you qualify for a one
              time referral discount of <strong>20%</strong> on a voyay!ge
              curated travel plan to be used on voyayge.com website only.
            </li>
          </ol>
          <h2 className="title is-5 margin-top-15px">
            Referral Credit & Referral Discount Exclusions:
          </h2>{" "}
          <p>
            Each Referral Credit and Referral Discount is non-transferable, not
            valid for cash or cash equivalent, cannot be combined with other
            offers.
          </p>
          <h2 className="title is-3 margin-top-15px">Following the Rules</h2>
          <p>
            Voyay!ge Inc., reserves the right to review, investigate, and
            disqualify any Referral Credit that violates the Voyay!ge’s
            Newsletter Referral Program, such as attempting to gain a Discount
            through self-referral by using a different email address, or
            distributing a Referral Link via commercial advertising or spam.
            Voyay!ge may delay any Discount for the purposes of investigation
            and may refuse to verify and process any transaction for any reason.
            If you unsubscribe from the newsletter, the Discount may no longer
            be valid or may be withdrawn.<br></br><br></br> Referrers should only invite
            a friend or family member after receiving prior permission from the
            Referred Customer to send. Referrers must comply with all up-to-date
            “SPAM” laws and marketing regulations, which may include the laws
            where the intended Referred Customers live. For example, emails must
            be created and distributed in a personal manner and bulk email
            distribution is prohibited. Any distribution of the Referral Link
            that could constitute unsolicited commercial email or "spam" under
            any applicable law or regulation is expressly prohibited and will be
            grounds for immediate termination of your account and exclusion from
            Voyay!ge’s Newsletter Referral Program. Voyay!ge automatically
            disqualifies suspicious referrals.
          </p>
          <h2 className="title is-3 margin-top-15px">Eligibility</h2>
          <p>
            Eligibility is limited to individuals only. Voyay!ge's Newsletter
            Referral Program cannot be used by businesses for affiliate lead
            generation, as determined in Voyay!ge’s sole discretion.
            <br></br><br></br> Voyay!ge’s employees are eligible to share their Referral
            Link with Referred Customers, however, employees will not earn any
            Referrer Credit.
          </p>
          <h2 className="title is-3 margin-top-15px">Privacy</h2>
          <p>
            By clicking on a Referral Link, the Referring Customer acknowledges
            that some of their personal information may be sent to the Referrer
            who provided the Referral Link as confirmation of the Referring
            Customer subscribing to the newsletter and the Referrer receiving a
            Referrer Discount. Accordingly, Voyay!ge strongly advises that
            Referring Customers only use a Referral Link that they receive from
            someone whom they personally know and trust. Any personal
            information collected, processed, and used as part of Voyay!ge’s
            Referral Program, including that of the Referrer and Referred
            Customer, will be used in accordance with Voyay!ge's Privacy Policy.
          </p>
          <h2 className="title is-3 margin-top-15px">
            Right to Close Accounts
          </h2>
          <p>
            Voyay!ge is not responsible for errors, omissions, malfunctions,
            interruptions, deletions, delays or failures of operations. Voyay!ge
            reserves the right to close the account(s) of any Referrer and/or
            Referred Customer and to request proper payment if the Referrer
            and/or Referred Customer attempts to use the Voyay!ge's Referral
            Program in a questionable manner or breaches any of these Terms &
            Conditions or is in violation of any law, statute or governmental
            regulation. All of Voyay!ge’s decisions are final and binding,
            including decisions as to whether a Discount is verified.
          </p>
          <h2 className="title is-3 margin-top-15px">
            Right to Cancel Program or Change Terms
          </h2>
          <p>
            Voyay!ge reserves the right to cancel the Voyay!ge Newsletter
            Referral Program or to change these Terms & Conditions at any time
            in its sole discretion. The Voyay!ge Referral Program is subject to
            modification or termination at any time without notice at Voyay!ge’s
            sole discretion. All Referral Credits will expire one year from the
            date of issuance, unless otherwise notified by Voyay!ge.
          </p>
          <div
            style={{
              fontSize: "16px",
              marginTop: "25px",
              //color: "#333333",
            }}
            className="content"
          ></div>
        </div>
        <div className="column is-2-desktop is-2-tablet is-hidden-mobile"></div>
      </div>
    </>
  )
}

export default NewsletterReferralTerms
