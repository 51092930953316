import React, { Fragment } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import NewsletterReferralTerms from "../Terms/NewsletterReferralTerms"

const TermsPage = () => {
  return (
    <Fragment>
      <NewsletterReferralTerms />
    </Fragment>
  )
}

export default () => (
  <Layout>
    <SEO pageTitle="Newsletter Terms" description="Newsletter Referral Terms" />
    <TermsPage />
  </Layout>
)
